/* font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800;900&display=swap');

/* end font */
/* Config */
:root {
    --primary-color: #0D4835;
    --secondary-color: #BDD3CC;
    --secondary-light-color: #E0F1EC;
    --border-color: #D3EAE3;
    --dark-color: #23262F;
    --white-color: #ffffff;
    --grey-color: #F4F6F6;
    --progress-bar-color: #e9e9e9;
}

.container {
    padding-left: 10px;
    padding-right: 10px;
}

.row {
    margin-left: -10px;
    margin-right: -10px;
}

[class*="col-"] {
    padding: 0 10px;
}

/*base */
body {
    margin: 0 auto;
    background-color: #FCFCFC;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: var(--dark-color);
}

/*bootstrap font size*/
.form-control,
.form-control-plaintext,
.dropdown-menu,
.input-group-text,
.custom-select,
.popover-header {
    font-size: 0.875rem;
}

/* Logo */
.img-fluid{
    max-height: 30px;
} 
/* font-size*/
.small-p {
    font-size: 12px;
    margin: 0;
}

.medium-p {
    font-size: 14px;
    margin: 0;
}

/* end font-size*/

/* border */
.container-bg {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background: var(--white-color);
    box-sizing: border-box;
    padding: 20px;
    margin-left: 15px;
}

/* end border */
/* Overflow */
.overflow-scroll-y {
    overflow-y: scroll;
}

.overflow-scroll-x {
    overflow-x: scroll;
}

/* container */
@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: none;
    }
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: none;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: none;
    }
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: none;
    }
}

/* colors */
.bg-secondary-color {
    background-color: var(--secondary-color) !important;
    color: var(--dark-color);
}

a.bg-secondary-color:hover {
    background-color: #B3D2D9 !important;
    color: var(--dark-color) !important;
}

.bg-secondary-light-color {
    background-color: #B3D2D9 !important;
    color: var(--primary-color);
}

.text-primary-color {
    color: var(--primary-color);
}

.text-light-color {
    color: var(--white-color) !important;
}

a.text-light-color:hover {
    color: #DAF3ED !important;
    text-decoration: none;
}

.text-dark-color {
    color: var(--dark-color) !important;
}

a.text-dark-color:hover {
    color: #000 !important;
    text-decoration: none;
}

.bg-grey-color {
    background-color: #F3F3F3;
}

.bg-primary-color {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}

.text-secondary-color {
    color: var(--secondary-color);
}

/* endcolors */

/* End container */

/* Input */
input {
    max-width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #B3D2D9;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
}

input:focus-visible {
    outline: none;
}

input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid var(--dark-color);
    margin: 5px;
}

input[type="radio"] {
    height: auto;
}

/* End Input */
/* Text-area */
textarea{
    width: 100%;
    height: 40px;
    max-height: 100px;
    border-radius: 6px;
    border: 1px solid #B3D2D9;
    padding: 8px 10px;
    vertical-align: middle;
    resize: vertical;
}
/* End Text Area */
/* icon */





/* icon */
.icon {
    height: 20px;
    width: auto;
}

.side-icon {
    height: 30px;
    width: 30px;
}

@media only screen and (max-width:991px) {
    .side-icon {
        height: 20px;
        margin-top: 5px;
    }
}

.drop-icon {
    height: 7px;
    width: auto;
}

/* Border */
.border {
    border: 1px solid #B3D2D9;
    border-radius: 8px;
}

.border-dashed {
    border: 2px dashed #b5b5b5;
    border-radius: 8px;
}

.border-right-secondary {
    border-right: 1px solid #B3D2D9;
}

.border-lg-right-none {
    border-right: none !important;
}

@media only screen and (max-width:576px) {
    .border-sm-right-none {
        border-right: none !important;
    }
}

@media only screen and (max-width:768px) {
    .border-md-right-none {
        border-right: none !important;
    }
}

@media only screen and (max-width:992px) {
    .border-lg-right-none {
        border-right: none !important;
    }
}

/* End Border */
/* Button */
.btn {
    padding: 8px 18px;
    border-radius: 6px;
    font-size: 14px;
    max-height: 36px;
    line-height: 18px;
    height: 36px;
}

.btn-cancel {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Select */
select {
    min-width: 200px;
    height: 39px;
    border-radius: 6px;
    border: 1px solid #B3D2D9;
    padding-left: 15px;
    padding-right: 25px;
    background-color: var(--white-color);
    appearance: none;
    background: url('../icons/dashboard-icon.png') no-repeat calc(100% - 10px) center;
}

/* End Select */
/* Button */

/* End Button */

/* Header */
.header {

    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
}

.header .navbar-nav .nav-item .nav-link {
    padding: 20px 1rem;
    color: var(--dark-color);
}

.header .navbar-nav .nav-item .nav-link:hover {
    color: var(--primary-color);
}

.header .navbar-nav .nav-item.show .nav-link {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.header .navbar-nav .nav-item .dropdown-item {
    padding: 0.5rem 1rem;
    width: auto;
    color: var(--white-color);
}

@media only screen and (max-width:991px) {
    .header .navbar-nav .nav-item .dropdown-item {
        padding: 0.5rem 1rem;
        width: auto;
        color: var(--dark-color);
    }
}

.header .navbar-nav .nav-item .dropdown-item:hover {
    background: var(--white-color);
    border-radius: 4px;
    color: var(--primary-color);
}

.header .navbar-nav .nav-item .dropdown-item:active,
.header .navbar-nav .nav-item .dropdown-item:focus {
    background: var(--white-color);
    border-radius: 4px;
    color: var(--dark-color);
    color: var(--primary-color)
}

.navbar {
    padding: 0px 14px;
    background-color: var(--secondary-color);
    width: 100%;
}

.navbar-brand {
    padding: 0;
}

.nav-link {
    color: #000000e6;
}

.search-bar {
    position: relative;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu.show {
        position: absolute;
        display: inline-flex;
        width: 100%;
        background-color: var(--primary-color);
        color: var(--white-color);
        align-items: center;
        padding: .5rem .5rem;
        overflow: auto;
        border: 0;
        border-radius: 0px 0px 0px 0px;
        margin-top: 0px;
        border-color: #e8e8e8;
        box-shadow: inset 1px 2px 2px hsl(0deg 0% 0% / 2%), -2px 3px 4px hsl(0deg 0% 0% / 2%), 2px 5px 8px hsl(0deg 0% 0% / 2%);
    }

    .menu.dropdown {
        position: unset;
    }
}


/* search Header */
.search-button {
    position: absolute;
    right: 0;
    height: 20px;
    font-size: 12px;
    width: 50px;
    margin-right: 10px;
    padding: 0;
}

.search-bar input.form-control {
    background: #1F67CF;
    border-radius: 10px;
    width: 250px;
}

.search-bar input::placeholder {
    color: var(--white-color);
}

.search-bar input.form-control:focus {
    background-color: var(--white-color);
    outline: none;
    box-shadow: none;
}

/* search Header */

/* end Header */

/*side-menu*/
.side-menu {
    padding-left: 15px;
    padding-top: 30px;
    height: calc(100vh);
    background-color: var(--primary-color);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    position: fixed;
    z-index: 998;
    color: var(--white-color);
    font-size: 12px;
}

.side-menu ul {
    padding-left: 0px;
    padding-right: 15px;
}

.side-menu ul li {
    list-style: none;
    margin-bottom: 30px;
}

.side-menu span {
    margin-top: 5px;
}

@media only screen and (max-width:991px) {
    .side-menu {
        width: 100vw;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        height: auto;
        padding-top: 5px;
        position: relative;
    }

    .side-menu ul {
        padding-left: 0px;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 0px;
    }

    .side-menu ul li {
        margin-bottom: 5px;
    }

    .navbar-collapse.show:after {
        content: '';
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-color: var(--white-color);
        left: 0;
        z-index: -15;
    }
}

/* end side-menu*/

/* Footer */
.footer {
    width: calc(100% - 100px);
    /* width: calc(100% - 140px); */
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.footer a {
    text-decoration: none;
}

@media only screen and (min-width:1024px) {
    .footer {
        margin-left: 60px !important;
    }
}

@media only screen and (max-width:991px) {
    .footer {
        width: 100%;
        margin-top: 30px;
    }
}

/* End Footer */
/* main-conent */
/* .main-content{
    width: calc(100% - 100px);    
} */
@media only screen and (min-width:992px) {
    .main-content {
        margin-left: 60px !important;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width:991px) {
    .main-content {
        width: 100%;

    }
}

.full-w-container {
    border-radius: 0;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media only screen and (min-width:991px) {
    .full-w-container {
        padding-left: 80px;
    }
}

.full-w-container .text-content li {
    list-style: none;
    position: relative;
}

.full-w-container .text-content li:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: var(--dark-color);
    left: -30px;
    top: 50%;
}

.small-text-card {
    height: auto;
    text-align: center;
    padding: 15px;
    border-radius: 14px;
}

.small-text-card .small-p {
    color: #787486;
}

/* graph-content */
.graph-content {
    margin-top: 15px;
}

.graph-content .dorpdown-list {
    display: flex;
    flex-direction: row;
}

.graph-content .dorpdown-list .dropdown-item {
    width: auto;
    height: 40px;
    margin: 10px;
    border-radius: 14px;
    color: var(--white-color);
}

.graph-content .dorpdown-list .dropdown-item p {
    margin: 0px;
}

/* end graph-content */


/* end main-content*/



/* only needed for fixed navbar */

.menu-2 {
    display: flex;
    align-items: center;
    flex-direction: row;


}

.menu-2 .box .container-2 input {
    width: 0;
    outline-offset: 0;
    border: none;
    border-bottom: 1px solid var(--primary-color);
    transition: all 0.4s ease-in-out;
    padding: 0px;
    border-radius: 0px;
}

.menu-2 .box:hover input {
    margin-left: 5px;
    width: 300px;
    outline-offset: 0;
    transition: all 0.4s ease-in-out;
    background-color: transparent;
}

.menu-2 .container-2 input#search:focus,
.container-2 input#search:active {
    margin-left: 5px;
    outline: none;
    width: 300px;
}

@media only screen and (max-width:991px) {
    .menu-2 .box:hover input {
        width: fit-content;
        outline-offset: 0;
        transition: all 0.4s ease-in-out;
    }

    .menu-2 .container-2 input#search:focus,
    .container-2 input#search:active {
        outline: none;
        width: fit-content;
    }
}

.menu-2>* {
    margin-right: 20px;
}

.menu-2 .user-icon>* {
    margin-right: 10px;
}

.menu-2 .user-icon span {
    text-decoration: none;
}

.menu-2 .dropdown-menu {
    margin-top: 11px;
}

@media only screen and (max-width:991px) {
    .menu-2 {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 15px;
    }

    .menu-2>* {
        margin: 8px 0px;
    }
}

.menu-2 .nav-link:hover {
    color: var(--primary-color);
}

.m-box {
    padding-top: 10px;
}

.m-box.box .container-2 input {
    width: calc(100% - 30px);
    max-width: 300px;
}

@media only screen and (max-width:991px) {

    /* .navbar-collapse{
    position: absolute;
    z-index: 10;
    top: 0px;
    background-color: rgb(255, 255, 255);
    width: 100vw;
    margin-top: 126px; 
    left: 0px;
    padding-left: 30px;
} */
    .dropdown-menu.show {
        border: none;
    }
}

.register-page {
    height: 100vh;
    background-color: var(--white-color);
    background-repeat: no-repeat;
    object-fit: contain;
}

/******* card ********/
.card {
    border-radius: 8px;
    color: var(--dark-color);
    background: var(--white-color);
    border: 1px solid var(--border-color);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.card-header {
    border: none;
}

.card-header h3 {
    font-weight: 600;
}

.card-sub-head {
    padding: 12px 20px;
    background-color: var(--grey-color);
}

.card-sub-head select {
    background-color: var(--white-color);
    margin: 5px 0;
}

/* .dropdown-menu{
    border: 1px solid #E2E2E2;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
} */
/******* End Card *******/

.register-page .card {
    max-width: 440px;
    padding: 40px;
}

/******* Login page & register Page *******/
.header-auth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    position: absolute;
    z-index: 999;
    padding: 20px;
}

.login {
    height: 100vh;
    background-color: var(--white-color);
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;
    object-fit: contain;
}

.recover {
    height: 100vh;
    background-color: var(--white-color);
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;
    object-fit: contain;
}

.login-container {
    display: flex;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #0D4835e6;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.login-container .input-icon select {
    min-width: 100%;
}

/* @media only screen and (max-width:990px){
    .login-container{
        background-color: hsla(190, 59%, 36%, 0.90);
    }
} */
.card.auth {
    max-width: 650px;
    padding: 40px;
    border-radius: 8px;
}

@media only screen and (min-width:769px) {
    .login .login-container .card.auth {
        width: 420px;
    }

    .recover .login-container .card.auth {
        width: 420px;
    }
}

@media only screen and (max-width:768px) {
    .recover .login-container .card.auth {
        max-width: 420px;
    }
}

.card.auth h5 {
    color: #777B82;
}

.card.auth .card-input {
    display: flex;
    flex-direction: column;
}

.login .card.auth .form-action {
    display: flex;
    justify-content: space-between;
}

.recover .card.auth .form-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.register .card.auth .form-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.card.auth .login-button {
    display: flex;
    justify-content: center;
}

.card.auth .login-button button {
    width: 100%;
    max-width: 340px;
    height: 48px;
    border-radius: 8px;
    border: none;
}

.card.auth .submit-button {
    display: flex;
    justify-content: center;
}

.card.auth .submit-button button {
    width: 100%;
    max-width: 340px;
    height: 48px;
    border-radius: 8px;
    border: none;
}

@media only screen and (max-width:991px) {
    .login-container {
        padding-bottom: 80px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .card.auth {
        padding: 20px;
        margin-top: 90px;
    }

    .card.auth .card-input input {
        width: 100%;
    }
}

.input-icon {
    position: relative;
}

.input-icon img {
    position: absolute;
    left: 15px;
    bottom: 50%;
    transform: translateY(50%);
}

.input-icon input {
    padding-left: 40px;
    width: 100%;
}

.input-icon select {
    text-indent: 30px;
    padding-left: 15px !important;
    width: 100%;
    min-width: auto;
}
.form-action label {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.register .login-container {
    padding-bottom: 40px;
    background: transparent;
}

@media only screen and (min-width:992px) {
    .register {
        height: 100vh;
        background-color: var(--white-color);
        background-repeat: no-repeat;
        object-fit: contain;
    }
}

.register .text-container {
    margin-top: 60px;
    padding-left: 20px;
    padding-top: 80px;
}

@media only screen and (max-width:1024px) {
    .register .text-container {
        margin-top: 0px;
        padding-left: 20px;
        padding-top: 80px;
    }
}

@media only screen and (max-width:768px) {
    .card-container .row {
        width: fit-content;
    }
}

/****** Account Page ******/
/* .account .card:first-child{
    height: 300px;
} */
.heading-line {
    min-height: 50px;
    position: relative;
    /* border: 1px solid #ccc; */
}

.heading-line h3 {
    position: relative;
}

.heading-line h3:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 35px;
    background-color: var(--primary-color);
    top: 0;
    left: -15px;
    transform: translateY(-10%);
}

/****** Catalog Page *******/
.date-picker {
    margin-left: 20px;
    white-space: nowrap;
}

.calender {
    position: relative;
}

.calender input {
    padding-left: 40px;
    width: 180px;
}

@media only screen and (min-width:992px) {
    .main-content.full-w-container {
        width: calc(100% - 60px);
        overflow: hidden;
    }
}

@media only screen and (max-width: 991px) {
    .main-content.full-w-container {
        width: 100%;
        margin-top: 0px;
    }
}

.input-group-addon {
    width: 20px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.full-w-container .sub-menu {
    padding-left: 0;
}

.full-w-container .sub-menu li {
    list-style: none;
    display: inline;
    padding: 10px;
}

@media only screen and (max-width:776px) {
    .datepicker {
        top: 275px !important;
    }
}

li.page-item {
    border: 1px solid #D9D9D9;
    overflow: hidden;
}

li.page-item.active {
    background-color: #F3F3F3;
}

li.page-item:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #D9D9D9;
}

li.page-item:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #D9D9D9;
}

.page-item .page-link {
    border: none;
    color: var(--dark-color);
}

.table-content select {
    min-width: 50px !important;
}

.table-catalog tr td {
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
}

.table-catalog tr th {
    padding: 15px 10px;
    border-bottom: 1px solid #ccc;
    font-weight: 600;
}

.table-catalog tr:last-child>td {
    border-bottom: none;
}

.table-catalog {
    white-space: nowrap;
}

.catalog {}

.add-icon img {
    width: 12px;
    height: 12px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none; */
    /* -webkit-appearance: none; */
    visibility: hidden;
    position: absolute;
    left: 0;
    height: 100%;
    width: 40px;
}

/* 
.datepicker{
    padding: 10px;
}
.date{
    width:180px; margin: 0 20px 20px 20px;
}
.datepicker .date > span:hover{
    cursor: pointer;
}
.datepicker td {
cursor: pointer;
}
.datepicker td:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    }
.datepicker td.day {
    padding: 10px;
    text-align: center;
}
.datepicker td.old.day {
    color: #ccc;
}
.datepicker td.new.day {
    color: #ccc;
}
.datepicker th.datepicker-switch {
    text-align: center;
}
.datepicker th.dow {
    text-align: center;
}
.datepicker th.prev,th.next{
    text-align:center;
    cursor: pointer;
}
.datepicker th.datepicker-switch{
    cursor: pointer;
} */


/****** Product page *******/
.product-tab {
    border-bottom: 0px;
}

.product-tab.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    border: none;
}

.product-head li {
    display: inline;
    position: relative;
    padding-right: 25px;
}

.product-head li:not(li:last-child):after {
    content: '';
    position: absolute;
    border: solid var(--dark-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 8px;
    right: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* Product Catalog tab */
.nav.nav-pills {
    width: fit-content;
}

.nav.nav-pills li {
    padding: 10px 30px;
}

.nav.nav-pills li a {
    color: var(--dark-color);
    text-decoration: none;
}

.nav.nav-pills li.active {
    background-color: var(--primary-color);
    border-radius: 8px 8px 0px 0px;
    color: var(--white-color);
}

.nav.nav-pills li:not(.active) {
    background-color: #F3F3F3;
    border-radius: 8px 8px 0px 0px;

}

.nav.nav-pills li.active a {
    color: var(--white-color);
    text-decoration: none;
    font-weight: 600;
}

.productlist .card ul {
    padding: 0;
}

.productlist .card ul li {
    list-style: none;
    padding: 5px 0px;
}

.product-category.card,
.product-sub-category.card,
.product-child-category.card {
    height: 100%;
    max-height: 250px;
    min-height: 250px;
    padding: 15px;
    overflow-y: scroll;
}

.product-child-category.card ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .product-child-category.card ul li:nth-child(even){
    background-color: rgb(242, 242, 242);
} */
.step-list {
    list-style: none;
    padding: 0;
}

.step-list li {
    margin: 15px 0px;
}

.step-list li span {
    position: relative;
    padding: 15px;
    color: var(--white-color);
}

.step-list li span:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: var(--primary-color);
    top: 0;
    border-radius: 100%;
    z-index: -1;
    transform: translateY(50%) translateX(30%);
    left: 0;
}





/* Terms and Conditions*/
.progress-bar {
    background-color: transparent;
    color: var(--dark-color);
}

.progress-bar .progress-sec {
    position: relative;
}

.progress-bar .progress-sec:not(.progress-sec.pending) {
    font-weight: 600;
}

.progress-bar .progress-sec .active {
    font-weight: 600;
}

.progress-bar p {
    margin-bottom: 5px;
    font-size: 12px;
}

.progress-bar span {
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    display: block;
    margin: 5px auto;
    border-radius: 10px;
    z-index: 1;
}

.progress-bar .pending span {
    background-color: var(--progress-bar-color);
}

.progress-bar .progress-sec:not(:last-child) span.circle:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    height: 3px;
    background-color: var(--primary-color);
    bottom: 9px;
    left: calc(50% + 10px);
    z-index: -1;
}

.progress-bar .progress-sec:not(:first-child) span.circle:before {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    height: 3px;
    background-color: var(--primary-color);
    bottom: 9px;
    left: 0;
    z-index: -1;
}

.progress-bar .progress-sec.pending:not(:last-child) span.circle:after {
    background-color: var(--progress-bar-color);
}

.progress-bar .progress-sec.pending:not(:first-child) span.circle:before {
    background-color: var(--progress-bar-color);
}

.progress-bar.mobile-progress-bar {
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
}

/* 
.progress-bar .progress-sec:after {
    position: absolute;
    content: '';
    width: 100%;
    background-color: var(--primary-color);
    height: 3px;
    bottom: 10px;
    left: 50%;
}

.progress-bar .progress-sec:last-child:after{
    visibility: hidden;
}

.progress-bar .progress-sec:before{
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--primary-color);
    bottom: 4px;
    left: 50%;
    border-radius: 50%;
    z-index: 999;
}

.progress-bar .progress-sec.pending:before, .progress-bar .progress-sec.pending:after{
    background-color: var(--progress-bar-color);
}

@media only screen and (max-width:768px){
    .progress-bar .progress-sec:after {
        left: -30px;
        width: calc(100% + 60px);
    }
    .progress-bar .progress-sec:first-child:after {
        left: 50%;
        width: calc(100% + 10px);
        z-index: 10;
    }
} */

/***** Dashboard *****/
.card-listing div:nth-child(1) .container .card {
    background-color: #DCDBFF;
    border: none;
}

.card-listing div:nth-child(2) .container .card {
    background-color: #d7ebfd;
    border: none;
}

.card-listing div:nth-child(3) .container .card {
    background-color: #fff2e4;
    border: none;
}

.card-listing div:nth-child(4) .container .card {
    background-color: #ffe8fd;
    border: none;
}

.card-listing div:nth-child(5) .container .card {
    background-color: rgb(255, 201, 187);
    border: none;
}

.db-icon img {
    max-width: 70px;
}

.nav-item.dropdown.menu.show a span img.light {
    display: inline;
}

.nav-item.dropdown.menu a span img.light {
    display: none;
}

.nav-item.dropdown.menu.show a span img.dark {
    display: none;
}

.nav-item.dropdown.menu a span img.dark {
    display: inline;
}

.tab-container {
    margin: 0px;
    list-style: none;
}

.tab-container li a {
    color: var(--dark-color);
    text-decoration: none;
}

.dashboard-notification .tab-container.nav-tabs .nav-link.active {
    background-color: transparent;
    font-weight: bold;
    border-bottom: 3px solid #576661;
    color: var(--dark-color);
}

.nav-tabs {
    border: none;
}

.dashboard-notification .tab-container.nav-tabs .nav-link {
    padding: 0.5rem 0rem;
    border-bottom: 3px solid var(--secondary-color);
}

.notification-list {
    overflow-y: scroll;
}

.notification-list::-webkit-scrollbar {
    display: none;
}

.badge {
    padding: 5px;
    background-color: #82948F;
    color: #E7E7E7;
    margin-left: 5px;
    font-weight: normal;
}

.active .badge {
    padding: 5px;
    background-color: #4B5552;
    color: var(--white-color);
    margin-left: 5px;
    font-weight: normal;
}

.day-section {
    background-color: var(--grey-color);
}

.day-section p {
    padding: 5px;
}

.tab-content {
    overflow: auto;
}

.tab-content::-webkit-scrollbar {
    width: 2px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification-list {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.notification-list-item {
    padding: 10px 20px;
    border-bottom: 1px solid #E8E8E8;
}

.notification-list-item span {
    color: #848587;
    font-size: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.notification-list-item a {
    font-weight: 600;
    text-decoration: none;
}

.notification-list-item.new p:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 10px;
    width: 5px;
    height: 5px;
    background-color: var(--secondary-color);
    border-radius: 50%;
}

.notification-list-item p {
    position: relative;
}

.db-table .table-catalog tr:nth-child(2n) {
    background-color: #F6F6F6;
}

.db-table .table-catalog tr th {
    color: var(--primary-color);
}

/***** company-info Page ******/
.input-icon.no-icon input {
    padding: 10px;
}

.input-icon.no-icon select {
    padding: 10px;
    text-indent: 0;
}

.input-icon.upload input {
    padding: 6px;
}

.prev,
.next {
    cursor: pointer;
    height: 17px;
    overflow: hidden;
    position: absolute;
    width: 10px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.next {
    right: 0;
    left: calc(50% + 2px);
}

.prev{
    left: calc(50% - 2px);
}

.prev:before,
.next:before {
    background-color: black;
    content: "";
    height: 17px;
    left: 5px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 17px;
}

.next:before {
    left: auto;
    right: 5px;
}

.prev:after,
.next:after {
    background-color: white;
    content: "";
    height: 100%;
    left: 8px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 17px;
}

.next:after {
    left: auto;
    right: 8px;
}

.slick-next:before,
.slick-prev:before {
    color: var(--primary-color);
}

.sub-slider-btn{
    margin-right: 10px;
}

.sub-slider-btn button{
    background-color: transparent;
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--secondary-color);
    transition: all 0.2s ease-in-out;
}
.sub-slider-btn button:hover{
    border: 1px solid var(--primary-color);
}

/******* Subscription Page *********/

.card-title {
    border-radius: 4px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary-color);
}

.sub-card {
    background: var(--secondary-light-color);
    border: 1px solid #B2D7DF;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 10px;
    position: relative;
    transition: all 0.2s;
    width: auto!important;
    display: block!important;
}

.sub-card .btn {
    background: var(--primary-color);
    color: var(--white-color);
}

.sub-card:hover .btn {
    background: var(--white-color);
    color: var(--dark-color);
}

.sub-card .sub-card-tag {
    padding: 20px 10px 10px 10px;
    text-align: center;
    color: var(--white-color);
    background: var(--primary-color);
    position: absolute;
    top: -15px;
    right: 20px;
    width: 90px;
    box-shadow: 2px 4px 13px 1px #00000024;
}

.sub-card .sub-card-tag:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -15px;
    top: 0;
    border-top: 15px solid transparent;
    border-right: 15px solid #003742;
    border-bottom: 0px solid transparent;
}

.sub-card .sub-card-tag:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -16px;
    left: 0px;
    border-left: 45px solid transparent;
    border-right: 44px solid transparent;
    border-top: 16px solid var(--primary-color);
}

.sub-card .sub-card-tag .sub-card-tag-price {
    font-weight: 600;
    font-size: 20px;
}

.sub-card .sub-card-tag .sub-card-tag-price-regular{
    text-decoration: line-through;
}

.sub-card .sub-card-tag .sub-card-tag-post-line {
    font-weight: 300;
    font-size: 12px;
}

.sub-card .sub-card-badge {
    margin-top: 20px;
    margin-bottom: 20px;
}

.sub-card .sub-card-badge img {
    max-width: 31px;
}

.sub-card .sub-card-title h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: var(--primary-color);
    margin-bottom: 22px;
}

.sub-card .sub-card-features {
    color: var(--primary-color);
    font-weight: 500;
    padding-left: 0px;
    list-style: inside;
    margin-bottom: 30px;
}

.sub-card .sub-card-features li {
    margin-bottom: 15px;
}

.sub-card .sub-card-features li.disabled {
    color: #7EA297;
}

.sub-card:hover {
    background: var(--primary-color);
    color: var(--white-color);
    cursor: pointer;
}

.sub-card:hover .sub-card-title h4,
.sub-card:hover .sub-card-features {
    color: var(--white-color);
}

.sub-card:hover .sub-card-tag {
    background: var(--white-color);
}

.sub-card:hover .sub-card-tag:after {
    border-top-color: var(--white-color);
}

.sub-card:hover .sub-card-tag {
    color: var(--primary-color);
}

.sub-card:hover .sub-feature-help-icon img {
    filter: invert();
}
/* Sub Card Active */
.sub-card.active-plan {
    background: var(--primary-color);
    color: var(--white-color);
    cursor: pointer;
}

.sub-card.active-plan .sub-card-title h4,
.sub-card.active-plan .sub-card-features {
    color: var(--white-color);
}

.sub-card.active-plan .sub-card-tag {
    background: var(--white-color);
}

.sub-card.active-plan .sub-card-tag:after {
    border-top-color: var(--white-color);
}

.sub-card.active-plan .sub-card-tag {
    color: var(--primary-color);
}

.sub-card.active-plan .sub-feature-help-icon img {
    filter: invert();
}
.sub-card.active-plan .btn{
    background: var(--secondary-color);
    color: var(--dark-color);
}
.sub-card .sub-feature-help-icon {
    display: inline-block;
}

.max-width-150px {
    max-width: 150px;
}

.table-wrapper {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: auto;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: var(--white-color);
    background-color: #004e5d;
    border-color: #004e5d;
}

.dropdown-item.active,
.dropdown-item:active {
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: #004e5d;
}

div.duration-list {
    list-style: none;
    margin: 0;
    margin-left: auto;
    align-items: center;
}

div.duration-list .slick-arrow{
    position: static;
    transform: translate(0);
}

div.duration-list .slick-list {
    flex: 1;
}

@media only screen and (min-width:1024px){
    div.duration-list {
        max-width: 550px;
    }
}
div.duration-list::-webkit-scrollbar-track
{
	background-color: transparent;
}

div.duration-list::-webkit-scrollbar
{
    visibility: hidden;
    height: 5px;
	background-color: #F5F5F5;
}

div.duration-list::-webkit-scrollbar-thumb
{
	background-color: #949494;
}

div.duration-list li button.duration-list {
    width: 100%;
    min-width: 100px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border-radius: 6px;
    background-color: var(--secondary-light-color);
}

div.duration-list li {
    padding-left: 5px;
    padding-right: 5px;
}

div.duration-list li:last-child{
    margin-right: 0;
}

div.duration-list li button.duration-list{
    font-size: 16px;
    font-weight: normal;
    /* display: flex; */
    /* height: 100%; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* background-color: transparent; */
    border: none;
    color: var(--primary-color);
}

div.duration-list li.active button{
    color: var(--white-color);
}

div.duration-list li button:hover {
    background-color: var(--secondary-color);
}

div.duration-list li span {
    font-size: 12px;
    font-weight: normal;
}

div.duration-list li.active button {
    background-color: var(--primary-color);
}
div.duration-list li.active button > *{
    color: var(--white-color);
    text-decoration: none;
}

/* 29-09-2022 */
.user-profile img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid var(--primary-color);
}

.table-catalog tr td {
    color: var(--primary-color);
}

.stat-active {
    background-color: #D2EBD3;
    padding: 5px;
    border-radius: 10px;
    color: #68BD6C;
    width: 100px;
}

.stat-inactive {
    background-color: #F5D0CD;
    padding: 5px;
    border-radius: 10px;
    color: #E16054;
    width: 100px;
}

.profile-icon {
    background-color: var(--white-color);
    padding: 10px 25px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    width: 100px;
}

.table-listing tr td {
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
    color: var(--primary-color);
}

.table-listing tr th {
    padding: 15px 10px;
    border-bottom: 1px solid #ccc;
    font-weight: 600;
}

.table-listing tr:last-child>td {
    border-bottom: none;
}

.table-listing {
    white-space: nowrap;
}


.profile-action {
    position: relative;
}

.profile-action a:first-child {
    margin-right: 10px;
}

.profile-action:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
}

.account .account-content {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.account .account-content .edit-icon {
    position: absolute;
    background-color: var(--primary-color);
    right: -75px;
    border-radius: 10px 0px 0px 10px;
    padding: 5px 10px 10px 10px;
    padding-right: 30px;
    transition: all 0.4s;
    z-index: 10;
}

.account .account-content .edit-icon.membership {
    right: -130px;
}

.account .account-content .membership-details {
    margin-top: 20px;
    padding-left: 10px;
    list-style: none;
}

.account .account-content .membership-details li {
    padding: 5px;
}

.account .account-content .edit-icon a {
    color: var(--white-color);
}

.account .account-content .edit-icon a p {
    display: inline;
    vertical-align: middle;
}

.account .account-content .edit-icon img {
    margin-right: 10px;
}

.account .account-content .edit-icon:hover {
    right: -20px;
}

.chng-pass .login-button button.cancel {
    border: 1px solid var(--primary-color);
}

.chng-pass .login-button {
    display: flex;
    justify-content: flex-start;
}

.chng-pass .login-button button {
    max-width: 340px;
    height: 48px;
    border-radius: 8px;
    border: none;
}

.chng-pass ul {
    padding-left: 12px;
}

.chng-pass ul li {
    list-style: none;
}

.chng-pass {
    position: relative;
    justify-content: center;
}

.account .chng-pass .chng-pass-content {
    width: 100%;
    max-width: 420px;
}

.account .chng-pass .chng-pass-content .input-icon input {
    padding-left: 15px;
    padding-right: 50px;
}

.account .chng-pass .chng-pass-content .input-icon img {
    left: unset;
    right: 15px;
}

@media only screen and (min-width:769px) {
    .account .chng-pass .chng-pass-content {
        max-width: 420px;
    }

    .account .chng-pass {
        padding-left: 30px;
    }
}

/*05-10-2022*/
/*User register page*/
.user-register {
    height: 100vh;
    background-color: var(--white-color);
    background-repeat: no-repeat;
    object-fit: contain;
}

.user-register .login-container {
    background-color: var(--white-color);
}

@media only screen and (min-width:769px) {
    .user-register .login-container .card.auth {
        width: 420px;
    }
}

@media only screen and (min-width: 991px) {
    .user-register .card.auth {
        margin-top: 100px;
    }
}

.user-register .card.auth .form-action {
    display: flex;
    justify-content: space-between;
}

.error-block {
    border-color: red;
}

.anticon {
    padding-top: 1px !important;
}
.anticon svg{
    vertical-align: baseline !important;
}

.ant-table-column-sorters {
    display: inline-flex !important;
    align-items: center !important;
}

.ant-table-filter-trigger {
    color: gray;
}

.ant-table-column-sorter {
    color: gray;
}

.login-input-container {
    position: relative;
}

.ant-upload-btn {
    padding: 7px !important;
}

.ant-modal-close-x {
    width: 40px !important;
    padding-top: 15px;
}

.text-bold {
    font-weight: bold;
}

.table-wrapper .ant-table {
    overflow: auto;
}

.ant-popover-message-title {
    padding-left: 25px !important;
    padding-top: 3px;
}

.steps-content {

    min-height: 200px;
    margin-top: 50px;
    padding-top: 45px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    min-height: 450px;

}
.steps-action {
    margin-top: 24px;

}
.ant-input-search-button{
    height: 39px;
}
.ant-form-item-explain-error {
    text-align: left;
}
.ant-table-thead > tr > th{
    font-weight: 700 !important;
}
.ant-collapse-expand-icon{
    padding-top: 4px;
}
.anticon-exclamation-circle{
    padding-top: 3px !important;
}
.jusify-content{
    text-align: justify;
}
/* Offer page start */
.offer-section {
    padding: 15px;
}

.offer-detail {
    padding-bottom: 20px;
}

.offer-detail--header {
    padding: 10px;
}

@media only screen and (min-width:992px) {
    .offer-detail--header {
        margin-bottom: 15px;
    }
}

.radio-btn {
    display: flex;
    margin-right: 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
}

.radio-btn label {
    margin: 0px;
    margin-left: 10px;
}

.offer-section select {
    min-width: auto;
}

.offer-section .btn.add-btn {
    margin: auto;
}

.preview-content {
    overflow: auto;
    background: var(--white-color);
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 10%);
}

.preview-table {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 20px;
    color: #2A3A51;
}

table.preview-table thead tr {
    height: 40px;
}

table.preview-table thead tr th {
    min-width: 140px;
    padding: 15px;
    font-weight: normal;
}

table.preview-table tbody tr td {
    padding: 15px;
}

table.preview-table tbody tr td {
    border-bottom: 1px solid var(--primary-color)1f;
}

table.preview-table tr td img {
    width: 30px;
    height: 30px;
    border: 1px solid #BDD3CC;
    border-radius: 6px;
}

table.preview-table tr td img:hover {
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
}

.trial-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--secondary-light-color);
}

.trial-card .btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--secondary-color);
    color: var(--primary-color);
}

.trial-card .btn span{
    display: flex;
    margin: 0px 5px;
    height: 100%;
}

.trial-card .trial-heading {
    position: relative;
    padding-bottom: 10px;
}

.trial-heading:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--secondary-color);
    bottom: 10px;
    left: 0;
}

.trial-card .trial-heading h4{
    font-size: 28px;
    color: var(--primary-color);
}

.trial-card .trial-content .trial-card-features {
    color: var(--primary-color);
    font-weight: 500;
    padding-left: 0px;
    list-style: inside;
    margin-bottom: 30px;
}

.trial-card .trial-content .trial-card-features li {
    margin-bottom: 15px;
}

.trial-card .trial-content .trial-card-features li.disabled {
    color: #7EA297;
}
.slick-dots {
    bottom: -19px !important;
}



.sub-card-tag + .sub-card-title {
    margin-top: 40px;
} 

.discount--badge {
    background-color: #FAE162;
    margin-left: -21px;
    margin-bottom: 10px;
    width: 120px;
    padding: 5px 21px;
    position: relative;
}

.discount--badge span{
    color: var(--primary-color);
    font-weight: 600;
}

.discount--badge:after {
    position: absolute;
    content: '';
    right: -11px;
    background-color: var(--secondary-light-color);
    z-index: 999;
    width: 22px;
    height: 22px;
    transition: background-color 0.2s;
    transform-origin: 50%;
    transform: rotate(45deg) rotateX(180deg);
}

.sub-card:hover .discount--badge:after{
    background-color: var(--primary-color);
}

.discount--badge-round + .sub-card-title {
    margin-top: 40px;
} 

.discount--badge-round{
    position: absolute;
    top: -15px;
    background-color: #FAE162;
    padding: 6px 10px ;
    border-radius: 6px;
    font-weight: 600;
    color: var(--primary-color);
}
.ant-avatar{
    color: black !important;
}
/* File Upload styles start */
.hjwpUg{
    min-width: 100% !important;
    border: dashed 2px gray !important;
    height: 100px !important;
}
.ldgBoH span {
    font-size: 15px !important;
}
/* File Upload styles end */
.active-link{
    color: #0D4835;
}
/* Multi Level List Inline */
.multi-level-list-inline-wrap{
    display: flex;
    overflow: auto;
}
.multi-level-list-inline-box{
    flex-basis: 20%;
    max-height: 350px;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    min-width: 240px;
    min-height: 250px;
}
.multi-level-list-inline-box ul{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.multi-level-list-inline-box ul .list-item{
    padding: 0px 10px;
    height: 34px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
}
.multi-level-list-inline-box ul .list-item:last-child{
    border-bottom: 1px solid #f5f5f5;
}
.multi-level-list-inline-box ul .list-item a{
    width: 100%;
    color: var(--primary-color);
}
.multi-level-list-inline-box ul .list-item:hover{
    background: var(--secondary-light-color);
    color: var(--primary-color);
}
.multi-level-list-inline-box ul .list-item:hover a{
    background: var(--secondary-light-color);
    color: var(--primary-color);
}
.multi-level-list-inline-box ul .list-item.active{
    background: var(--secondary-light-color);
    color: var(--primary-color);
}
.multi-level-list-inline-box ul .list-item.active a{
    color: var(--primary-color);
}
.multi-level-list-inline-box ul .list-item a.multi-level-list-select-btn{
    width: 70px;
    padding: 2px 10px;
    height: 24px;
    border-radius: 4px;
}
.right-arrow-icon:after{
    content: '';
    position: absolute;
    border: solid var(--dark-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 50%;
    right: 10px;
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform: rotate(-45deg) translateY(-50%);
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}
