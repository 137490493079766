.mew-phone-input {
    position: relative;
    width: 100%;
}
.mew-input-container span.mew-select-left-icon {
    height: 100%;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    z-index: 10;
}